/* eslint-disable */
import { loggedInPage } from '@/api-routes.js'
import { mapGetters, mapActions } from "vuex"
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import CheckboxPicker from '@/components/elements/form/pickers/CheckboxPicker'
import RadioPicker from '@/components/elements/form/pickers/Radio'
import { ValidationObserver } from "vee-validate"
import "@/components/packages/veevalidate/rules"
// Vee-validation
import InputWithValidation from '@/components/elements/form/inputs/InputWithValidation'
import SelectWithValidation from '@/components/elements/form/inputs/SelectWithValidation'
import TextareaWithValidation from '@/components/elements/form/inputs/TextareaWithValidation'

export default {
  // Set props to T in router (this.$route.params.id = this.id)
  // props: ["id"],
  components: {
    HeroBar,
    CardComponent,
    RadioPicker,
    CheckboxPicker,
    ValidationObserver,
    InputWithValidation,
    SelectWithValidation,
    TextareaWithValidation
  },

  data() {
    return {
      // name of modul: users, persmissions, roles,...
      moduleName: null,
      name: null,
      // formMethod should be lower case
      formMethod: "post",
      method: "create",
      message: "General error",
      isHidden: true,
      isEditable: true
    }
  },

  computed: {
    ...mapGetters([
      'item',
      'items',
      'isLoggedUser',
      'isLoggedIn',
      "loading",
      "error"
    ]),

    // Used on "auth" forms
    form() {
      // let data = this.user;
      let form = {};
      for (let field in this.formObj) {
        form[field] = this.formObj[field];

      }
      // console.log('form !!! ', form)
      return form;
    },

    isReadonly() {
      // this.type refers to input class type (is-static, is-success, ...)
      if(this.isEditable) {
        this.type = ''
        return false;
      }
      this.type = 'is-static'
      return true;
    },
  },

  watch: {
    // watch user computed proprerty in mapGetters
    isLoggedUser (value) {
      // console.log('login watch -logged in!!!', value)
      if (value !== null && (value !== undefined || this.isLoggedIn === undefined)) {
        // console.log('should be redirected: ', loggedInPage)
        this.$router.push(loggedInPage)
      }
    }
  },

  methods: {
    ...mapActions([
      "setError",
      "checkUnique",
      "fetchItem",
      "saveItem"
    ]),

    /**
     * submit will dynamically handle create or update action according to the
     * method defined - for new user
     */
    submit () {
      if(typeof(this.slug) !== 'undefined' && this.slug.length) {
        let payload = {
          endpoint: this.endpoint + '/' + this.slug,
          method: this.formMethod,
          data: this.fetchData()
        }

        this.saveItem(payload)
          .then(response => {
            // console.log("form submit: ", baseUrl);
            // clean objects
            this.formSession =  {}
            // location.reload();
            this.redirectTo();
          })
          .catch(error => {
            console.log("ERROR form submit: ", error)
          });
      } else {
        this.signUserUp(this.form)
      }
    },

    // Convert array to object
    getCheckboxObject (values) {
      let items = {}
      for(let i=0; i < values.length; i++) {
        // items[i] = [values[i].name, values[i].slug,values[i].description]
        items[values[i].slug] = values[i].name
      }
      // console.log(items)
      return items
    },

    // permissions with roles - to remove all permissions bellonging to
    // the role
    // convert multidimensional array to object
    getCheckboxObjectDouble (values) {
      let items = {}
      // console.log('values: ', Object.values(values))
      // console.log('keys: ', Object.keys(values))
      Object.values(values).forEach(item => {
        for (const [key, value] of Object.entries(item)) {
          // console.log(`${key}: ${value}`);
          items[key] = value
        }
      })
      /*Object.entries(values).map(role => {
        Object.entries(role[1]).map(permission => {
          // console.log(permission)
          // first part is role, second permission
          items[role[0] + '_' + permission[0]] = permission[1]
        })
      })*/

      // console.log('items: ' , items)

      return items
    },

    /**
     * helper method for 'setFromValues'
     */
    buildArray (values) {
      let items = []
      for(let i=0; i < values.length; i++) {
        items.push(values[i].slug)
      }
      return items
    },

    /**
     * Fetch all relevant data for the form before submit
     */
    fetchData (item) {
      let data = {}
      let form = this.form

      // set property defined in the form
      for (let property in form) {
        // console.log('prop in a form: ', property)
        data[property] = form[property]
      }

      return data
    },

    getErrors () {
      if(this.error !== null || this.error !== true) {
        let message = this.error;
        return message;
      }
    },

    reset () {
      this.$refs.form.reset();
      // console.log('template reset')
      /*this.$buefy.snackbar.open({
        message: 'Reset successfully',
        queue: false
      })*/
    },

    /**
     * go back
     */
    goBack() {
      this.$router.go(-1);
    },

    /**
     * on update, create redirect to specific page (list of roles,
     * permissions, ...)
     */
    redirectTo () {
      // console.log('this type: ', this.moduleName)
      if (this.isLoggedUser.roles === 'admin' || this.isLoggedUser.roles === 'owner') {
        if (this.moduleName !== null) {
          this.$router.push('/' + this.moduleName)
          return
        }
      }
      this.$router.push('/')
    },

    handleSubmit () {
      // apply some other actions
      return false;
    },

    toggle () {
      return this.isHidden = ! this.isHidden
    },

    /**
     * validate unique field (usually title, name)
     *
     * @param  {string} value
     * @param  {string} field type of the field (name, title, slug)
     * @param  {string} old old value for the field (name, title, slug)
     *
     * @return {void}
     */
    validateUnique (value, field, old) {
      // console.log('validateUnique: ', value , 'name: ', this.$refs.provider);
      if(value === '') {
        return false
      }
      if(field !== 'email') {
        this.form.slug = this.generateSlug(value)
      }
      // if value is not null
      if (value !== this.name) {
        let endpoint = this.endpoint + '/unique'

        if (typeof field === "undefined" || field === null) {
          // Some forms have title, like posts
          field = 'name'
        }

        let data = {
          type: field,
          value: value,
          oldValue: old
        };

        let payload = {
          endpoint: endpoint,
          data: data
        };

        // this.checkUnique(payload)
        this.checkUnique (payload)
          .then(response => {
            // console.log('res error returned', response)
          })
          .catch(error => {
            // console.log('error returned', error)
          })
        // }
      }
    },

    /**
     * generate informative slug
     * real slug is generated in backend (using Laravel feature
     * to keep them consistent)
     *
     * @param  {string} string
     *
     * @return {string}
     */
    generateSlug (string) {
      let slug = ""
      slug = string.toString().toLowerCase()
        // Replace spaces with -
        .replace(/\s+/g, '-')
        // Replace & with 'and'
        .replace(/&/g, '-and-')
        // Remove all non-word chars
        .replace(/[^\w\-]+/g, '')
        // Replace multiple - with single -
        .replace(/--+/g, '-');
      // console.log('slug: ', slug)
      return slug
    }
  },

  created() {
    // console.log('form mixin: ', this.id)
  }

}

