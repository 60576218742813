<template>
  <b-field v-if="!table" grouped group-multiline>
    <div v-for="(v,k) in options" class="control" :key="k">
      <b-checkbox v-model="newValue" :native-value="k" :disabled="disabled" @input="input" :type="type" @change.native="checked(k)" :true-value="isSelected" :false-value="!isSelected">
        {{ v }}
      </b-checkbox>
    </div>
  </b-field>
  <div v-else>
    <b-table
        :striped="true"
        :hoverable="true"
        default-sort="1.name"
        :paginated="paginated"
        :per-page="perPage"
        :data="Object.entries(data)"
      >
      <template slot-scope="props">
        <b-table-column
          :label="$getTranslation('form.name.label')"
          field="1.name"
          sortable
          :searchable="searchable"
        >
          {{ props.row[1].name }}
        </b-table-column>
        <b-table-column
          :label="$getTranslation('form.slug.label')"
          field="1.slug"
          sortable
          :searchable="searchable"
        >
          {{ props.row[1].slug }}
        </b-table-column>
        <b-table-column
          :label="$getTranslation('form.description.label')"
          field="1.description"
          sortable
          :searchable="searchable"
          width="400"
        >
          {{ props.row[1].description }}
        </b-table-column>
        <b-table-column custom-key="active">
          <b-checkbox v-model="newValue" :native-value="props.row[1].slug" :disabled="disabled" @input="input" :type="type" @change.native="checked(props.row[1].slug)" :true-value="isSelected" :false-value="!isSelected">
          </b-checkbox>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'CheckboxPicker',
  props: {
    options: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    value: {
      default: []
    },
    disabled: {
      default: false
    },
    table: {
      default: false
    },
    data: {
      default: null
    },
    paginated: {
      default: false
    },
    perPage: {
      default: 15
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newValue: [],
      isSelected: false
    }
  },
  watch: {
    value (newValue) {
      this.newValue = newValue
    }
  },
  methods: {
    input () {
      this.$emit('input', this.newValue)
    },

    checked(value) {
      // console.log('checked btn ', value, ' selected: ', this.isSelected)
      let index = this.newValue.indexOf(value)
      if (index !== -1 ) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }

      this.$emit('checked', value, this.isSelected)
    },
  },
  created () {
    this.newValue = this.value
  }
}
</script>
