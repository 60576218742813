<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    v-slot="{ errors, valid }"
    :ref="$attrs.provider"
  >
  <!-- autocomplete="new-password" will cleanup email and password field, other values as 'new-email' or 'new-input' won't work once you include password field -->
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0] || responseError, 'is-success': valid }"
      :message="errors"
      autocomplete="new-password"
    >
      <b-input autocomplete="new-password" v-model="innerValue" v-bind="$attrs"></b-input>
      <b-tooltip v-if="typeof tooltip !== 'undefined'"
          :label="tooltip"
          multilined>
          <b-icon
          icon="mdi mdi-information-outline"
          size="is-small" />
      </b-tooltip>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate"
import store from '@/store'

export default {
  components: {
    ValidationProvider
  },
  props: {
    // reference id, example: password and confirm-password,
    // where latter is referrencing password field
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    responseError: {
      type: String || null
    },
    tooltip: {
      type: String || null
    }
  },
  data () {
    return {
      'innerValue': ''
    }
  },

  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
    // Add error manually
    responseError(newVal) {
      // console.log('response error!!!!!: ', newVal, this.$attrs)
      this.$refs.provider.applyResult({
        // array of string errors
        errors: [newVal],
        // boolean state
        valid: false,
        // should be empty since this is a manual error.
        failedRules: {}
      })
      // this.innerValue = newVal
    }
  },

  created() {
    // this.locale = store.state.language.language
    // console.log('locale?', this.locale, store.state.language.language)
    if (this.value) {
      // console.log('inner value on create: ', this.value, this.$refs, this.$attrs)
      this.innerValue = this.value
    }
  }
}
</script>
