/**
 * Managing validation rules
 * 'length' for checkboxes
 * 'verify_password' not yet in use, use it when finished whole login
 *                   system
 */
import {
  required,
  confirmed,
  length,
  email
} from "vee-validate/dist/rules"
import { extend } from "vee-validate"
import axios from 'axios'
import { adminUrl } from '@/api-routes.js'

extend("required", {
  ...required,
  message: "This field is required"
})

extend("email", {
  ...email,
  message: "This field must be a valid email"
})

extend("confirmed", {
  ...confirmed,
  message: "This field confirmation does not match"
})

extend("length", {
  ...length,
  message: "This field must have 2 options"
})



extend('max', {
  validate(value, { length }) {
    return value.length <= length
  },
  params: ['length'],
  message: 'The {_field_} field must have max {length} characters'
})

extend('min', {
  validate(value, { length }) {
    return value.length >= length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('verify_password', {
  message: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return strongRegex.test(value)
  }
})

// The messages getter may also accept a third parameter that includes the data we returned earlier.
/*extend('unique', {
  validate: checkUnique,
  message: (field) => `${field} already taken`
})

function checkUnique (value) {
  console.log('check unique:', value)

  new Promise((resolve, reject) => {
    let data = {
      value: value,
      type: 'slug'
    }
    axios.post(adminUrl + 'permissions/unique', data)
      .then(response => {

        if (response.status === 200) {
          console.log('check unique VALID: ' ,  response.data);
          return resolve({
            valid: true,
            data: {
              message: ` is OK.`
            }
          });

        } else {
          console.log('check unique : ' ,  response.status, response);
          return resolve({
            valid: false,
            data: {
              message: ` error.`
            }
          });
        }
      })
      .catch(error => {
        console.log('catch error: ', error)
        return resolve({
          valid: false,
          data: {
            message: `Error.`
          }
        });
      });
  })
}*/
/*const isUnique = (value) => {
  return axios.post('/api/validate/email', { email: value }).then((response) => {
    // Notice that we return an object containing both a valid property and a data property.
    return {
      valid: response.data.valid,
      data: {
        message: response.data.message
      }
    };
  });
};*/
